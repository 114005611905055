<template>
  <div>
    <div class="drop-box-overlay" v-if="droped" @click="droped=false"></div>
    <el-button type="text" class="search-btn" @click="searching=true" v-if="!searching">
      <img class="bar-search" :src="require('@/assets/images/bar_search_.png')" />
    </el-button>
    <template v-else>
      
      <div class="announcement search-bar">
        <span class="close-btn" @click="searching=false"><i class="el-icon-close"></i></span>
        <div class="box">
          <label for="keyword">
            <div class="bar-search-icon" @click="searchPost(0)">
              <img class="normal-icon" :src="require('@/assets/images/bar_search_.png')" />
              <img class="focus-icon" :src="require('@/assets/images/bar_search_1.png')" />
            </div>
          </label>
          <input type="text" class="input-x" placeholder="Search" v-model="keywords" id="keyword" v-mtfocus @keyup.enter="searchPost">
          <el-button type="text" icon="el-icon-caret-bottom" class="drop-btn" @click="openDrop"></el-button>
        </div>
        
        <div class="drop-box" v-if="droped">
          <div class="area">
            <div class="line">
              <div class="tit">Includes the words</div>
              <md-field md-inline>
                <label>Enter words found in the title or contents</label>
                <md-input v-model="keywords"></md-input>
              </md-field>
            </div>
            <div class="line">
              <div class="tit">Classes</div>
              <tag-selecter :classId="classIdList" @onCheck="handleTagCheck"/>
            </div>
            <div class="line">
              <div class="tit">Students</div>
              <md-field md-inline>
                <label>Enter a name</label>
                <md-input v-model="studentName"></md-input>
              </md-field>
            </div>
            <div class="line clearfix">
              <div class="tit">Date posted</div>
              <md-datepicker class="datepicker" v-model="beginDate"  md-immediately>
                <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
                <label>From date</label>
              </md-datepicker>
              <span class="datepickerspan">to</span>
              <md-datepicker class="datepicker" v-model="endDate"  md-immediately>
                <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
                <label>To date</label>
              </md-datepicker>
            </div>
          </div>
          <div class="hr"></div>
          <div class="btns">
            <baseButton class="cancel-btn" @click="droped=false">Cancel</baseButton>
            <baseButton class="base-button primary" @click="searchPost">Search</baseButton>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TagSelecter from "./detail/modules/tagSelecter";
import StudentSelecter from "./detail/modules/studentSelecter";
import moment from 'moment'
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {TagSelecter,StudentSelecter},
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  props:{
    query: {
      default:()=>{},
      type: Object
    },
    classId:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      classIdList: [],
      studentName: '',
      classList: [],
      searching:false,
      droped:false,
      keywords:'',
      status: '',
      favourite: 0,
      students:[],
      studentsList:[],
      tag:[],
      note:'',
      beginDate:null,
      endDate:null
    };
  },
  mounted(){
    this.fetchStudents()
  },
  watch:{
    'query':'queryInt'
  },
  created() {
    this.queryInt()
  },
  directives: {
    'mtfocus' (el, binding, vnode) {
    let mtinput = el
    mtinput.onfocus = function () {
      mtinput.parentNode.className = 'box focus'
    }
    mtinput.onblur = function () {
      mtinput.parentNode.className = 'box'
    }
    } 
  },
  methods: {

    moment,

    queryInt(){
      if(this.query) {
        this.keywords = this.query.keywords || ''
        this.studentName = this.query.studentName || ''
        this.beginDate = this.query.postedDateStart || ''
        this.endDate = this.query.postedDateEnd || ''
        this.classIdList = this.query.classIds ? this.query.classIds.split('@') : []

        if(this.query.keywords || this.query.classIds || this.query.studentName || this.query.postedDateStart || this.query.postedDateEnd) {
          this.searching = true
        }
      }
    },

    searchPost(s){
      const keywords = this.keywords,
      classIds = [],
      studentName = this.studentName,
      postedDateStart = this.beginDate ? moment(this.beginDate).format('YYYY-MM-DD') : '',
      postedDateEnd = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : '',
      query = {}

      // 简易搜索 
      if(s === 0 && keywords) {
        this.droped = false
        this.$router.push({ 
          name: 'announcementSearch', 
          query: {
            keywords: keywords
          }
        })

        return false
      } 

      for (let item of this.tag) {
        classIds.push(item.classId)
      }

      if(keywords) {
        query.keywords = keywords
      }
      if(classIds.length > 0){
        query.classIds = classIds.join('@')
      }
      if(studentName){
        query.studentName = studentName
      }
      if(postedDateStart){
        query.postedDateStart = postedDateStart
      }
      if(postedDateEnd){
        query.postedDateEnd = postedDateEnd
      }

      if(this.$route.name === 'announcementSearch'){
        this.droped = false
      }

      if(keywords || classIds.length > 0 || studentName || postedDateStart || postedDateEnd) {
        this.$router.push({ 
          name: 'announcementSearch', 
          query
        })
      }
    },

    openDrop(){
      this.droped = true
      // this.keywords = ''
      // this.studentName = ''
      // this.beginDate = null
      // this.endDate = null
    },
    handleTagCheck(val){
      this.tag = [...val]
    },
    handleStudentCheck(val){
      this.students = [...val]
    },
    
    async fetchStudents(){
      var res = await Ajax.get("/usermanage/student/getStudentByClassId", {
        classId:this.classId,
      })
      if(res.code=='0000'){
        this.studentsList = res.data.map(o=>{
          return {
            id:o.id,
            avatar:o.photo,
            name:o.firstName+' '+o.lastName
          }
        })
      }
      
    }
  },
};
</script>
<style lang='less' scoped>
.search-btn{
  position: absolute;
  right:0;
  top:50%;
  transform: translate(0,-50%);    
  font-size: 18px;
  color:#fff;
}
.drop-box-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4000;
  overflow: hidden;
}
.search-bar{
  font-family: Roboto;
  z-index: 4001;
  height:48px;
  position: absolute;
  left:0;
  top:50%;
  transform: translate(0,-50%);
  background: #1a73e8;

  .close-btn{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    line-height: 48px;
    text-align: center;
    color:#fff;
    float:left;
    cursor: pointer;

    .el-icon-close{
      font-size: 22px;
      vertical-align: middle;
    }

    &:hover {
      background: #ffffff33;
    }
  }
  .box{
    margin-left:8px;
    margin-top:2px;
    float:left;
    height:44px;
    line-height:44px;
    border-radius: 4px;
    background: #4B8EE7;
    font-size: 0;

    .focus-icon {
      display: none;
    }

    &.focus {
      background: #fff;

      .bar-search-icon:hover {
        background: #eee;
      }

      .drop-btn:hover {
        background: #eee;
      }

      input {
        color: #20212480;

        &:-internal-autofill-previewed,
        &:-internal-autofill-selected {
          -webkit-text-fill-color: #20212480 !important;
          transition: background-color 5000s ease-in-out 0s !important;
        }
      }

      .focus-icon {
        display: inline-block;
      }
      
      .normal-icon {
        display: none;
      }

      .drop-btn {
        color: #606367 !important;
      }
    }

    label{
      display:inline-block;
      font-size: 18px;
      margin-left:8px;
    }
    i{
      vertical-align: middle;
    }
    input{
      background:transparent;
      display:inline-block;
      border:0 none;
      outline:none;
      color:#fff;
      margin-left:10px;
      width: 630px;
      height: 43px;
      font-size: 20px;
      font-family: 'Open Sans';

      &:-internal-autofill-previewed,
      &:-internal-autofill-selected {
        -webkit-text-fill-color: #fff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      &::placeholder {
        color: #fff;
        opacity: .5;
      }
    }

    .drop-btn{
      color:#fff;
      padding:10px;
      margin-right:8px;
      border-radius: 50%;
      vertical-align: middle;
      margin-top: -13px;

      &:hover {
        background: #ffffff33;
      }
    }
  }
 
  .drop-box{
    position: absolute;
    left:56px;
    top:46px;
    background:#fff;
    border-radius: 8px;
    width: 724px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    font-size:16px;
    color:#202124;
    line-height: 20px;
    .area{
      padding:10px 24px 0;
    }
    .hr{
      height:0;
      border-bottom:1px solid #E0E0E0;
    }
    .line{
      position: relative;
      min-height: 48px;
      padding-left: 154px;
      .tit{
        position: absolute;
        left:0;
        top:9px;
        line-height: 48px;
        color:#202124;
        font-size:14px;
        font-weight: 500;
      }
      .md-field {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;

        label {
          color: #B4B4B4;
        }
      }
      
      .datepickerspan{
        float:left;
        line-height: 68px;
        margin:0 15px;
      }

      &:last-child {
        .tit{
          top:13px;
        }
      }
    }
    .datepicker{
      float:left;
      width:160px;

      .md-date-icon {
        display: none;
      }
    }
    .btns{
      text-align: right;
      padding:10px 20px;
      .el-button{
        margin-left:30px;
        &.cancel{
          color:#5F6368;
        }
      }
    }
  }
}
.student-selecter{
  min-height: 49px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  /deep/ .edit-btn{
    top:10px;
  }
}

.search-btn {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  display: inherit;
  text-align: center;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}

.bar-search-icon {
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 50%;
  margin-top: -4px;

  img {
    display: inherit;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}
</style>