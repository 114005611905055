
<template>
  <img
          class="svg-icon"
          :src="icon"
        />
</template>

<script>
export default {
  name: "SvgIcon",
  data() {
    return {
      
    };
  },
  props: {
    iconClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    icon(){
      return require("@/assets/images/icons/"+this.iconClass+".svg")
    }
  },
  methods: {
   
  },
};
</script>
<style lang='less' scoped>

</style>