
<template>
  <div class="student-selecter">
    <div class="value" v-for="item in selected" :key="item.id">
      <span class="avatar" :style="'background-image:url('+item.avatar+')'"></span>
      <span class="name">{{item.name}}</span>
    </div>
    <el-button class="edit-btn" type="text" @click="editStudent" v-if="!readOnly"><svg-icon iconClass="icon-edit"/></el-button>
    <div class="selecter-box" v-if="selecting">
      <div class="hd">Select student</div>
      <div class="ct">
        <div class="values">
          <div class="value" v-for="item in selectedTemp" :key="item.id">
            <span class="avatar" :style="'background-image:url('+item.avatar+')'"></span>
            <span class="name">{{item.name}}</span>
            <el-button type="text" icon="el-icon-error" @click="checkItem(item)"></el-button>
          </div>
        </div>
        <div class="options">
          <div class="tit">SEARCH RESULTS</div>
         
          <div class="item" @click="checkAll">
            <span class="checkbox" :class="{'checked':isCheckAll}">
              <i class="el-icon-check"></i>
            </span>
            <span class="avatar" :style="'background-image:url('+require('@/assets/images/icons/student-all.png')+')'"></span>
            <div class="wd">
              <div class="wd-main">
                <span class="name">Select all</span>
              </div>
            </div>
          </div>
          <div class="item" v-for="item in options" :key="item.id"  @click="checkItem(item)">
            <span class="checkbox" :class="{'checked':selectedTemp.find(o=>o.id==item.id)}">
              <i class="el-icon-check"></i>
            </span>
            <span class="avatar" :style="'background-image:url('+item.avatar+')'"></span>
            <div class="wd">
              <div class="wd-main">
                <span class="name">{{item.name}}</span>
                <span class="mail">-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ft">
        <span class="btn" @click="handleCancel">
          Cancel
        </span>
        <span class="btn" :class="{'disabled':confirmDisable}" @click="handleOk">
          Select
        </span>
      </div>
    </div>
  </div>
    
</template>

<script>

import SvgIcon from "@/components/SvgIcon";
export default {
  components: {SvgIcon},
  props: {
    defaultValue:{
      type:Array,
      default:()=>[]
    },
    readOnly:{
      type: Boolean,
      default: false,
    },
    options:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      selected:[], // 当前值
      selecting:false,
      selectedTemp:[], // 当前操作中的值，未确定
    }
  },
  computed:{
    isCheckAll(){
      return this.selectedTemp.length!==0&&this.selectedTemp.length === this.options.length
    },
    confirmDisable(){
      return JSON.stringify(this.selected) == JSON.stringify(this.selectedTemp)
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.selected = [...this.defaultValue]
      this.selectedTemp = [...this.defaultValue]
    },
    editStudent(){
      this.init()
      this.selecting = true
    },
    handleCancel(){
      this.selecting = false
    },
    handleOk(){
      if(this.confirmDisable){
        return
      }
      this.selecting = false
      this.selected = [...this.selectedTemp]
      this.$emit('onCheck',this.selected)
    },
    checkAll() {
      if(this.isCheckAll){
        // 取消全选
        this.selectedTemp = []
      }else{
        // 全选
        this.selectedTemp = [...this.options]
      }
    },
    checkItem(item) {
      const _i = this.selectedTemp.findIndex(o=>o.id==item.id)
      if(_i>=0){
        // 取消选中
        this.selectedTemp.splice(_i,1)
      }else{
        // 选中
        this.selectedTemp.push(item)
      }
    },
    
  },
};
</script>
<style lang='less' scoped>
.student-selecter{
  padding-right: 34px;
  position: relative;
  .value{
    display: inline-block;
    height:32px;
    line-height: 30px;
    padding:0 5px;
    border:1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 0;
    margin-right: 8px;
    cursor:default;
    .avatar{
      width:24px;
      height:24px;
      border-radius: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      display: inline-block;
    }
    .name{
      font-size:14px;
      color:#202124;
      margin-left:8px;
      vertical-align: middle;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 20px;
      margin-left:8px;
    }
  }
  
  .edit-btn{
    position: absolute;
    right:0;
    top:4px;
    padding:0;
    width:24px;
    height:24px;
    border:0 none;
    .svg-icon{
      width:24px;
      height:24px;
    }
  }
  .selecter-box{
    position: absolute;
    z-index: 999;
    left:0;
    top:-62px;
    width:100%;
    background:#FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    .hd{
      padding:20px 24px;
      line-height: 22px;
      color:#202124;
      font-size: 16px;
      border-bottom: 1px solid #E0E0E0;
    }
    .ct{
      padding: 24px;
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
      }
      .options{
        margin-top:25px;
        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          height:36px;
          line-height: 36px;
          padding:0 5px;
          font-size: 0;
          margin-top:20px;
          cursor:pointer;
          .checkbox{
            height:20px;
            width:20px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            border:1px solid #444;
            border-radius: 1px;
            vertical-align: middle;
            margin-right: 18px;
            .el-icon-check{
              font-size: 16px;
              color:#fff;
              display:  none;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
          .avatar{
            width:36px;
            height:36px;
            border-radius: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            vertical-align: middle;
            display: inline-block;
            margin-right: 18px;
          }
          .wd{
            vertical-align: middle;
            display: inline-block;
          }
          .wd-main{
            display: flex;
            flex-direction: column;
          }
          .name{
            font-size:14px;
            color:#202124;
            line-height: 20px;
          }
          .mail{
            font-size:12px;
            color:#202124;
            line-height: 16px;
          }
        }
      }
    }
    .ft{
      padding:8px 20px;
      line-height: 44px;
      border-top: 1px solid #E0E0E0;
      text-align: right;
      .btn{
        display: inline-block;
        line-height: 44px;
        color:#5F6368;
        font-size: 13px;
        padding: 0 12px;
        margin-left: 12px;
        cursor: pointer;
        &.disabled{
          color:#B4B4B4;
          cursor: not-allowed;
        }
      }
    }
  }
}

</style>