
import store from './../../../store'

export function hasPermission(permission){
  const ls = store.getters.rolePermission
  const userType = store.state.user.userType

  // View Create Edit Full
  const kind = permission ? permission.split('_')[0] : ''
  const _role = ls.find(o=>o.indexOf(kind)>=0) || ''

  // console.log(_role, userType)
  if(_role.length==0 && userType != '1'){
    // 没有相关权限
    return false
  }else{
    const _opt = _role.split('_')[1] || ''
    if(_opt.length==0 && userType != '1'){
      return false
    }else{
      if(userType === '1' || _opt == 'Full'){
        return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit' || permission == kind+'_Full'
      }else if(_opt == 'Edit'){
        return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit'
      }else if(_opt == 'Create'){
        return permission == kind+'_View' || permission == kind+'_Create'
      }else if(_opt == 'View'){
        return permission == kind+'_View'
      }else{
        return false
      }
    }
  }

}