
<template>
  <div class="tag-selecter" @click="openSelecter">
    <div class="value" v-for="item in selected" :key="item.classId" v-show="item.className != 'Select all'"> 
      <div v-if="item.ai" class="no" :style="'background-color:' + item.themeColor">{{ item.ai }}</div>
      <span class="name">{{item.className}}</span>
      <el-button type="text" icon="el-icon-error" v-if="!readOnly"  @click="checkItem(item)"></el-button>
    </div>
    <el-button class="drop-btn" type="text" v-if="!readOnly" icon="el-icon-caret-bottom"></el-button>
    <div class="selecter-box" v-if="selecting">
      <div class="options">

        <div class="item" @click="allcheck">
          <span class="checkbox" :class="{'checked': allselected}">
            <i class="el-icon-check"></i>
          </span>
          <div class="wd">
            <div class="wd-main">
              <span class="name">Select all</span>
            </div>
          </div>
        </div> 

        <div class="item" v-for="(item, index) in classList" :key="index" @click="checkItem(item)">
          <span class="checkbox" :class="{'checked':selected.find(o=>o.classId==item.classId)}">
            <i class="el-icon-check"></i>
          </span>
          <div v-if="item.ai" class="no" :style="'background-color:' + item.themeColor">{{ item.ai }}</div>
          <div class="wd">
            <div class="wd-main">
              <span class="name">{{item.className}}</span>
            </div>
          </div>
        </div>        

      </div>
    </div>
  </div>
    
</template>

<script>

import SvgIcon from "@/components/SvgIcon";
import { mapState } from "vuex";
import { Ajax } from "@/common";
export default {
  components: {SvgIcon},
  props: {
    classId:{
      default:()=>[],
      type: Array
    },
    defaultValue:{
      type:Array,
      default:()=>[]
    },
    readOnly:{
      type: Boolean,
      default: false,
    },
    options:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      allselected: false,
      classList: [],
      selected:[], // 当前值
      selecting:false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  mounted(){
    this.getClass()
    document.addEventListener('click',e => { 
     if(!this.$el.contains(e.target)){
        this.selecting = false//点击其他区域关闭
      }
     })
    this.init()
  },
  methods: {

    allcheck() {
      const arr = this.classList
      this.allselected = !this.allselected
      if(this.classList.length != this.selected.length) {
        // 选项未被全部选中的情况
        this.selected = []
        for(let item of this.classList) {
          this.selected.push(item)
        }
      } else {
        // 选项全部选中的情况
        this.selected = []
      }

      this.$emit('onCheck',this.selected)
    },

    checkItem(v) {
      console.log(v)
      const _i = this.selected.findIndex(o=>o.classId==v.classId)
      if(_i >= 0) {
        // 取消选中
        this.selected.splice(_i,1)
      } else {
        // 选中
        this.selected.push(v)
      }

      if(this.classList.length != this.selected.length) {
        this.allselected = false
      }else{
        this.allselected = true
      }

      this.$emit('onCheck',this.selected)
    },
    
    getClass() {
      let formData = new FormData();
      formData.append("staffId", this.user.userId);
      formData.append("locationId", this.user.locationId);

      Ajax.post(
        "/usermanage/class/selectByStaffId", 
        formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then((res) => {
          let tempdata = [], temparr, classData = [], key = '', idArr = [], selectedarr = []
          for(let item in res.data) {
            key = item
            for(let item_ of res.data[key]) {
              if(!idArr.includes(item_.classId)) {
                idArr.push(item_.classId)
                classData.push(item_)
              }
            }
          }

          for(let item of classData) {
            temparr = item
            temparr.select = false
            temparr.ai = 
              item.className.indexOf(' ') > -1 ? 
              (item.className.split(' ')[0].substring(0, 1).toUpperCase() + item.className.split(' ')[1].substring(0, 1)).toUpperCase() : 
              item.className.substring(0, 1).toUpperCase();   
            tempdata.push(temparr)

            for(let itemd of this.classId) {
              if(itemd === item.classId) {
                selectedarr.push(item)
              }
            }
          }
          this.classList = tempdata

          if(selectedarr.length > 0) {
            this.selected = selectedarr
          }

          if(this.selected.length === this.classList.length) {
            this.allselected = true
          }
        });
    },

    _getClass() {
      let data = {
        locationId: this.user.locationId
      }
      Ajax.get("/usermanage/class/selectByCompanyIdLocationId", data).then((res) => {
        let tempdata = [], temparr, selectedarr = []
        for(let item of res.data) {
          temparr = item
          temparr.select = false
          temparr.ai = item.classNameReplace.length > 2 ? item.classNameReplace.substring(0, 2) : item.classNameReplace
          tempdata.push(temparr)
          
          for(let itemd of this.classId) {
            if(itemd === item.id) {
              selectedarr.push(item)
            }
          }
        }
        this.classList = tempdata

        if(selectedarr.length > 0) {
          this.selected = selectedarr
        }

        if(this.selected.length === this.classList.length) {
          this.allselected = true
        }
      });
    },

    init(){
      // this.selected = [...this.defaultValue]
    },

    openSelecter(){
      if(this.readOnly){
        return
      }
      this.selecting = true
    }
    
  },
};
</script>
<style lang='less' scoped>
.tag-selecter{
  font-family: Roboto;
  padding: 0 34px 8px 0;
  position: relative;
  border-bottom:1px solid rgba(0,0,0,0.42);
  min-height: 49px;
  cursor: pointer;

  .no {
    display: inline-block;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background: #7e7e7e;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    vertical-align: middle;
  }

  .drop-btn{
    position: absolute;
    right:0;
    top:50%;
    transform: translate(0,-50%);
    padding: 0;
    color:#333;
  }
  .value{
    display: inline-block;
    height:32px;
    line-height: 30px;
    padding:0 8px;
    border:1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 0;
    margin: 8px 8px 0 0;
    cursor:default;
   
    .name{
      font-size:14px;
      color:#202124;
      vertical-align: middle;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 20px;
      margin-left:8px;
    }
  }
  
  .selecter-box{
    font-family: Roboto;
    position: absolute;
    z-index: 999;
    left:0;
    top:calc(100% + 1px);
    width:100%;
    padding-right: 2px;
    background:#FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
      }
      .options{
        padding:8px 0;
        max-height: 176px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }

        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          height:28px;
          line-height: 28px;
          padding:0 16px;
          font-size: 0;
          cursor:pointer;

          &:first-child {
            .name {
              font-size: 16px;
            }
          }
            

          &:hover {
            background-color: #eee;
          }

          .checkbox{
            width: 18px;
            height: 18px;
            line-height: 18px;
            border: 2px solid #606368;
            border-radius: 3px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            .el-icon-check{
              font-size: 18px;
              color:#fff;
              display:  none;
              font-weight: bold;
              margin: -2px 0 0 -2px;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
          
          .wd{
            vertical-align: middle;
            display: inline-block;
          }
          .wd-main{
            display: flex;
            flex-direction: column;
          }
          .name{
            font-size:14px;
            color:#202124;
            line-height: 20px;
          }
          
        }
      }
    
  }
}

</style>